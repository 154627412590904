import Head from 'next/head'
import { GIT_BRANCH } from '../../../data/gitBranch'

const PageHead = ({ title }) => {
    let suffix = ''
    if (GIT_BRANCH == 'develop') {
        suffix = ' [PREVIEW]'
    }

    return (
        <Head>
            <title>{`${title} — VisCap.ai${suffix}`}</title>
        </Head>
    )
}

export default PageHead
